import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Report, ReportsService } from "@hydrantid/acm-client";
import { createEmbeddingContext, EmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { BehaviorSubject, firstValueFrom, ReplaySubject } from "rxjs";
import { IconsService } from "../../services/icons/icons.service";
import { map } from "rxjs/operators";
import { CachedDataService } from "../../services/cached-data/cached-data.service";
import { Store } from "@ngrx/store";
import { AppUser, AppUserSelectors } from "../../modules/shared/app-user";

@Component({
    selector: "app-report-embed",
    templateUrl: "./report-embed.component.html",
    styleUrl: "./report-embed.component.scss",
})
export class ReportEmbedComponent {
    showList$ = new ReplaySubject<boolean>(1);
    pageTitle$ = new ReplaySubject<string>(1);
    reports$ = new ReplaySubject<Report[]>(1);
    error$ = new BehaviorSubject<string | undefined>(undefined);
    passedAccountId$ = this.activatedRoute.queryParams.pipe(map((params) => params.accountId));

    constructor(
        private reportsService: ReportsService,
        private activatedRoute: ActivatedRoute,
        private cachedDataService: CachedDataService,
        protected store: Store<{ appUser: AppUser }>,
        public icons: IconsService,
    ) {
        this.initializePage();
    }

    async initializePage(): Promise<void> {
        const queryParams = await firstValueFrom(this.activatedRoute.queryParams);
        let accountId = "";
        if (queryParams.accountId) {
            accountId = queryParams.accountId;
        } else {
            // Get user's first account
            const firstAccountId = await firstValueFrom(this.store.select(AppUserSelectors.firstAccountId));
            if (!firstAccountId) {
                this.error$.next("No account found");
                return;
            }
            accountId = firstAccountId;
        }
        const params = await firstValueFrom(this.activatedRoute.params);
        if (params.key) {
            this.showList$.next(false);
            this.loadReport(params.key, accountId);
        } else {
            this.showList$.next(true);
            this.pageTitle$.next("Reports");
            this.loadReportList(accountId);
        }
    }

    async loadReportList(accountId: string): Promise<void> {
        this.reports$.next(await firstValueFrom(this.cachedDataService.getReportsForAccount(accountId)));
    }

    async loadReport(reportId: string, accountId: string): Promise<void> {
        const reports = await firstValueFrom(this.cachedDataService.getReportsForAccount(accountId));
        const report = reports.find((r) => r.id === reportId);
        this.pageTitle$.next(report?.label || "Report");
        try {
            const reportUrl = await firstValueFrom(this.reportsService.embedReport(reportId));
            this.embedReport(reportUrl, accountId);
            this.error$.next(undefined);
        } catch {
            this.error$.next("Failed to load report");
        }
    }

    /* istanbul ignore next */
    async createAWSEmbeddingContext(): Promise<EmbeddingContext> {
        return createEmbeddingContext();
    }
    async embedReport(url: string, accountId: string): Promise<void> {
        const embeddingContext = await this.createAWSEmbeddingContext();

        const frameOptions = {
            url,
            container: "#report",
            height: "100%",
            width: "100%",
            resizeHeightOnSizeChangedEvent: true,
        };

        const contentOptions = {
            parameters: [
                {
                    Name: "accountId",
                    Values: [accountId],
                },
            ],
            toolbarOptions: {
                export: false,
                undoRedo: false,
                reset: false,
            },
            attributionOptions: {
                overlayContent: false,
            },
        };
        await embeddingContext.embedDashboard(frameOptions, contentOptions);
    }
}
